export const viewItem = (item, gtm) => {
  if (gtm !== undefined) {
    clearEcommerce(gtm)
    gtm.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { 'list': 'Product List' },
          products: [
            {
              id: item.sku,
              brand: item.publisher,
              name: item.title,
              price: item.basePrice,
              sku: item.sku
            }
          ]
        }
      }
    })
  }
}

export const updateCart = (item, gtm, cartMethod) => {
  if (gtm !== undefined) {
    clearEcommerce(gtm)
    gtm.push({
      event: cartMethod,
      ecommerce: {
        add: {
          products: [
            {
              id: item.product.sku,
              brand: item.product.publisher,
              name: item.product.title,
              price: item.product.basePrice,
              sku: item.product.sku,
              quantity: item.quantityToOrder
            }
          ]
        }
      }
    })
  }
}

export const purchase = (item, orderProducts, gtm) => {
  if (gtm !== undefined) {
    // TODO the item object will have a property called promotionApplication
    // that array of objects will have a promotion object
    // the promotion object could have a code
    // if it does send the first one to GA
    // GA doesn't support multiple coupon codes for this dimension
    // it's likely that most customers will only have one

    clearEcommerce(gtm)
    gtm.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: item.id,
            affiliation: 'ECOMM',
            tax: item.taxPrice,
            revenue: item.totalPrice,
            shipping: item.shippingPrice
          },
          products: orderProducts
        }
      }
    })
  }
}

export const buildProductList = (batches) => {
  const orderProducts = []
  batches.forEach((x) => {
    const batchProducts = x.orderLineItems.map((y) => {
      const oliProduct = y.product
      return {
        id: oliProduct.sku,
        brand: oliProduct.publisher,
        name: oliProduct.title,
        price: oliProduct.basePrice,
        sku: oliProduct.sku,
        quantity: y.quantity
      }
    })
    batchProducts.forEach((r) => {
      orderProducts.push(r)
    })
  })
  return orderProducts
}

export const search = (searchTerm, gtm) => {
  if (gtm !== undefined) {
    gtm.push({
      event: 'search',
      ecommerce: {
        search: {
          keyword: searchTerm
        }
      }
    })
  }
}
const clearEcommerce = (gtm) => {
  gtm.push({ ecommerce: null })
}
